export default [
  {
    prop: 'id',
    label: 'ID',
    type: 'id'
  },
  {
    prop: 'name',
    label: 'Tên',
    minWidth: 150,
    type: 'text'
  },
  {
    prop: 'group',
    label: 'Bảng',
    minWidth: 150,
    type: 'text'
  }
]